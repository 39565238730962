


































































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import EntityListItemComponent from '@/components/EntityListItemComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import WidgetBackground from '@/utils/widgets/WidgetBackground';
import InputSearchComponent from '@/components/InputSearchComponent.vue';
import { DataWithNull } from '@/utils/types/WidgetDataWithNull';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import RouteHelper from '@/utils/helpers/RouteHelper';
import EntityType from '@/utils/enums/EntityType';
import SpeakerRole from '@/models/graphql/SpeakerRole';

@Component({
  components: {
    EntityListItemComponent,
    ButtonComponent,
    InputSearchComponent,
  },
})
export default class EntityListComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  baseStoreName = 'EntityListWidgetStore';

  @Prop({
    required: false,
    default: null,
  })
  private readonly title!: string | null;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly data!: DataWithNull[];

  @Prop({
    required: false,
    default: null,
  })
  private readonly empty!: string | null;

  @Prop({
    required: false,
    default: 4,
  })
  private readonly amount!: number;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly type!: string | null;

  @Prop({
    required: false,
    default: 'grid',
  })
  private readonly display!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly actionButton!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  private readonly displaySearch!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly displayViewAll!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly viewAllText!: string;

  @Prop({
    required: false,
    default: null,
  })
  private viewAllUrl!: string;

  @Prop({
    required: false,
    default: null,
  })
  private orderBy!: string;

  private searchValue = '';

  private contentList: DataWithNull[] = [];

  private EntityType = EntityType;

  private get backgroundClass(): string {
    const list: string[] = [];
    if (this.background) {
      list.push(WidgetBackground[this.background]);
    }
    if (this.classes) {
      list.push(this.classes);
    }
    return list.join(' ')
      .trim();
  }

  private get gaps(): string {
    const list: string[] = [];
    if (this.topGap) {
      list.push(this.WidgetTopGap[this.topGap]);
    }
    if (this.bottomGap) {
      list.push(this.WidgetBottomGap[this.bottomGap]);
    }
    return list.join(' ')
      .trim();
  }

  private get orderedData(): DataWithNull[] | SpeakerRole[] {
    if (this.type === EntityType.SPEAKER.toLowerCase() && this.orderBy.length > 0) {
      const speakerRolesList = [...this.contentList] as unknown as SpeakerRole[];
      const orderByValue = this.orderBy.split(',')
        .find((field: string) => field.startsWith('_speakerRoleDisplaySequence'));
      if (orderByValue === '_speakerRoleDisplaySequence_asc') {
        return speakerRolesList.sort((a, b) => (a.displaySequence || 0) - (b.displaySequence || 0));
      }
      return speakerRolesList.sort((a, b) => (b.displaySequence || 0) - (a.displaySequence || 0));
    }
    return this.contentList;
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('data', { immediate: true })
  updateContent(): void {
    this.contentList = this.data;
  }

  private viewAllClick(): void {
    RouteHelper.universalNavigation(this.viewAllUrl, this.$route.params, this.$router);
  }

  private applySearch(text: { query: string }): void {
    if (text.query !== '') {
      this.$store.dispatch(`${this.widgetStorePath}/loadEntityListSearch`, text.query)
        .then((response) => {
          if (response) {
            this.contentList = response;
          } else {
            this.contentList = [];
          }
        })
        .catch(() => {
          this.contentList = [];
        });
    } else {
      this.contentList = this.data;
    }
  }
}
